import { OutletDetails, OutletListItem } from '@ancon/wildcat-types'
import useTranslation from 'next-translate/useTranslation'
import OrderTypeIcon from '@ancon/wildcat-ui/web/orderweb/OrderTypeIcon'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import getOutletOrderFormats from '@ancon/wildcat-utils/outlet/getOutletOrderFormats'
import HeadingText from '@ancon/wildcat-ui/web/orderweb/HeadingText'
import OutletLocationMarkerIcon from '@ancon/wildcat-ui/shared/static/placeholders/outlet-location-map-marker.svg'

import { ScriptStatus, useScript } from '../../../app/hooks/useScript'
import { GoogleMapsAPIUrl } from '../../../app/constants'
import MapView from '../../../app/components/MapView'
import OrderFormatText from '../../../app/components/OrderFormatText'

import styles from './OutletDetailsMetaData.module.scss'

type OutletDetailsMetadataProps = {
  outletDetails: OutletDetails
}

export default function OutletDetailsMetadata({
  outletDetails,
}: OutletDetailsMetadataProps) {
  const { t } = useTranslation('common')
  const scriptStatus = useScript(GoogleMapsAPIUrl)
  const {
    supportedOrderFormats,
    orderFormatSettings,
    businessNumber,
    name,
    latitude,
    longitude,
  } = outletDetails
  const orderFormats = getOutletOrderFormats({
    orderFormatSettings,
    supportedOrderFormats,
  } as OutletListItem)

  return (
    <>
      <div className={styles.mapContainer}>
        <div className={styles.mapWrapper}>
          {scriptStatus === ScriptStatus.Ready && (
            <MapView
              markerCoordinates={{
                latitude,
                longitude,
              }}
              containerStyle={{
                height: '160px',
              }}
              zoom={15}
              gestureHandling="none"
              fullscreenControl={false}
              markerConfig={{
                icon: {
                  url: OutletLocationMarkerIcon.src,
                  scaledSize: new window.google.maps.Size(50, 50),
                },
                optimized: true,
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.outletMetaContainer}>
        <HeadingText as="h2">{name}</HeadingText>
        <div className={styles.orderFormats}>
          {orderFormats.map(orderFormat => (
            <div key={orderFormat} className={styles.orderFormat}>
              <OrderTypeIcon orderFormat={orderFormat} />
              <OrderFormatText orderFormat={orderFormat} />
            </div>
          ))}
        </div>
        <div className={styles.businessInfo}>
          <BodyText color="body-1">
            {t('outletDetailsModal.businessNumber', {
              businessNumber,
            })}
          </BodyText>
        </div>
      </div>
    </>
  )
}
