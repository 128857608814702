import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import useTranslation from 'next-translate/useTranslation'
import {
  OrderFormat,
  OutletState,
  ServiceTime,
  ServiceTimeKindType,
  ErrorCode,
  BoxPickUpStation,
} from '@ancon/wildcat-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { useRouter } from 'next/router'
import { batch } from 'react-redux'
import createCodedError from '@ancon/wildcat-utils/error/createCodedError'
import isOutletAcceptingPreOrders from '@ancon/wildcat-utils/outlet/isOutletAcceptingPreOrders'
import noop from 'lodash/noop'

import Modal from '../../../app/components/modal/Modal'
import OrderTimeSelectionStep from '../../../outlet/components/orderConfigurationModal/OrderTimeSelectionStep'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { outletsSelectedOutletListItemSelector } from '../../store/outletsSelectors'
import OrderConfigurationModalHeader from '../../../outlet/components/orderConfigurationModal/OrderConfigurationModalHeader'
import { ModalHeaderProps } from '../../../app/components/modal/ModalHeader'
import { AppRoutes, OrderFormatSlugMap } from '../../../app/constants'
import Button from '../../../app/components/Button'
import useRouteMatch from '../../../app/hooks/useRouteMatch'
import {
  outletFiltersSectionSelector,
  outletFiltersServiceTimeSelector,
  outletListItemSelector,
} from '../../../outlet/store/outletSelector'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  outletSetIsVisibleServiceTimeKindSelectionModal,
  outletSetOutletFilters,
} from '../../../outlet/store/outletSlice'
import getTranslatedError from '../../../app/utils/getTranslatedError'
import { appShowError } from '../../../app/store/appSlice'
import { ErrorModalType } from '../../../app/types'
import usePrevious from '../../../app/hooks/usePrevious'
import BoxPickupSections from '../../../outlet/components/boxPickupOrderConfigureModal/BoxPickupSections'
import { fetchOutletStations } from '../../../outlet/store/outletThunks'

import styles from './NewOrderTimeConfigureModal.module.scss'
import OrderConfigureTimeInfo from './OrderConfigureTimeInfo'

const initialServiceTime = {
  kind: ServiceTimeKindType.AsSoonAsPossible,
  time: '',
}

type NewOrderTimeConfigureModalProps = {
  selectedOrderFormat?: OrderFormat
  onClose: () => void
  isOpen?: boolean
}

export default function NewOrderTimeConfigureModal({
  selectedOrderFormat,
  onClose,
  isOpen = false,
}: NewOrderTimeConfigureModalProps) {
  const isBoxPickup = selectedOrderFormat === OrderFormat.BoxPickup
  const prevIsBoxPickup = usePrevious(isBoxPickup)
  const { t, lang } = useTranslation('common')
  const router = useRouter()
  const { query } = router
  const dispatch = useAppDispatch()
  const [isShowTimePicker, setIsShowTimePicker] = useState(
    !query?.serviceTime ? !!isBoxPickup : false,
  )
  const [serviceTime, setServiceTime] = useState<ServiceTime>(
    isBoxPickup
      ? { kind: ServiceTimeKindType.AtSpecifiedTime, time: '' }
      : initialServiceTime,
  )
  const [selectedSection, setSelectedSection] = useState<BoxPickUpStation>()
  const [sections, setSections] = useState<BoxPickUpStation[]>([])
  const [isSectionsFetching, setIsSectionsFetching] = useState(false)
  const isOutletPage = useRouteMatch([`${AppRoutes.Outlet}/`])
  const outletsOutletListItem = useAppSelector(
    outletsSelectedOutletListItemSelector,
  )
  const outletOutletListItem = useAppSelector(outletListItemSelector)
  const outletFilterServiceTime = useAppSelector(
    outletFiltersServiceTimeSelector,
  )
  const outletFiltersSection = useAppSelector(outletFiltersSectionSelector)
  const outletListItem = isOutletPage
    ? outletOutletListItem
    : outletsOutletListItem
  const isOrderNow = serviceTime.kind === ServiceTimeKindType.AsSoonAsPossible
  const pickerTimeMoment = serviceTime?.time
    ? moment(serviceTime.time)
    : undefined
  const isAcceptingPreOrders =
    outletListItem?.state === OutletState.Closed &&
    isOutletAcceptingPreOrders(outletListItem.orderFormatSettings)
  const isFirstStep = !isShowTimePicker || (isBoxPickup && !pickerTimeMoment)

  useEffect(() => {
    async function showOutletClosedError() {
      const error = await getTranslatedError(
        createCodedError(
          'Restaurant not open yet',
          ErrorCode.RequestedOutletIsNotOpen,
        ),
        undefined,
        lang,
      )
      dispatch(
        appShowError({
          ...error,
          errorModalType: ErrorModalType.OutletNotAvailableForOrders,
        }),
      )
    }
    if (
      isOutletPage &&
      !outletFilterServiceTime &&
      query?.serviceTime === undefined
    ) {
      if (outletOutletListItem) {
        const isClosed = outletOutletListItem.state === OutletState.Closed

        if (isClosed) {
          showOutletClosedError()
        } else {
          dispatch(outletSetIsVisibleServiceTimeKindSelectionModal(true))
        }
      }
    } else if (
      isOutletPage &&
      outletFilterServiceTime &&
      isBoxPickup &&
      !outletFiltersSection
    ) {
      // Show section selection modal
      dispatch(outletSetIsVisibleServiceTimeKindSelectionModal(true))
    }
  }, [
    outletFilterServiceTime,
    isOutletPage,
    query?.serviceTime,
    dispatch,
    outletOutletListItem,
    lang,
    outletFiltersSection,
    isBoxPickup,
  ])

  useEffect(() => {
    if (prevIsBoxPickup !== isBoxPickup && !query?.serviceTime) {
      setIsShowTimePicker(!!isBoxPickup)
    }
  }, [isBoxPickup, prevIsBoxPickup, query?.serviceTime])

  useEffect(() => {
    // Reset state when modal is closed
    if (!isOpen) {
      setSelectedSection(undefined)
    }
  }, [isOpen])

  useEffect(() => {
    // Fetch sections
    if (isOpen && isBoxPickup && isOutletPage) {
      setIsSectionsFetching(true)
      dispatch(fetchOutletStations({ outletId: outletListItem?.id! }))
        .unwrap()
        .then(({ items }) => {
          setSections(items)
        })
        .catch(noop)
        .finally(() => {
          setIsSectionsFetching(false)
        })
    }
  }, [dispatch, isBoxPickup, isOpen, outletListItem?.id, isOutletPage])

  function handleOnPressBack() {
    setIsShowTimePicker(false)
  }

  const CustomHeader = useCallback(
    (headerProps: ModalHeaderProps) => (
      <OrderConfigurationModalHeader
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...headerProps}
        onBack={handleOnPressBack}
        isSecondStep={!isFirstStep}
        className={clsx({
          [styles.header]: !isFirstStep,
        })}
      />
    ),
    [isFirstStep],
  )

  function handleOnChangeServiceTime(newServiceTime: ServiceTime) {
    const isNavigateToOutletPage = !isOutletPage && outletListItem?.urlSlug
    if (isNavigateToOutletPage) {
      const { urlSlug } = outletListItem
      router.push({
        pathname: `outlet/${urlSlug}`,
        query: {
          orderFormat: OrderFormatSlugMap[selectedOrderFormat as never],
          serviceTime: newServiceTime.time,
        },
      })
    } else {
      setServiceTime(newServiceTime)
      setIsShowTimePicker(false)
    }
  }

  function handleOnClickOrderNow() {
    setServiceTime({
      kind: ServiceTimeKindType.AsSoonAsPossible,
      time: '',
    })
  }

  function handleOnClickOrderLater() {
    setServiceTime({
      kind: ServiceTimeKindType.AtSpecifiedTime,
      time: '',
    })
  }

  useEffect(() => {
    if (isOpen) {
      if (isAcceptingPreOrders) {
        handleOnClickOrderLater()
      } else {
        handleOnClickOrderNow()
      }
    }
  }, [isOpen, isAcceptingPreOrders])

  function handleOnClickScheduleOrder() {
    setIsShowTimePicker(true)
  }

  function handleOnClickContinue() {
    if (isOutletPage) {
      batch(() => {
        dispatch(
          outletSetOutletFilters({
            orderFormat: selectedOrderFormat,
            serviceTime: query?.serviceTime
              ? outletFilterServiceTime
              : serviceTime,
            section: selectedSection,
          }),
        )
        dispatch(outletSetIsVisibleServiceTimeKindSelectionModal(false))
      })
    } else if (outletListItem?.urlSlug) {
      const { urlSlug } = outletListItem
      router.push({
        pathname: `outlet/${urlSlug}`,
        query: {
          orderFormat: OrderFormatSlugMap[selectedOrderFormat as never],
          serviceTime: serviceTime.time,
        },
      })
    }
  }

  function handleOnClose() {
    onClose()
    setServiceTime(initialServiceTime)
    setIsShowTimePicker(false)
  }

  const isContinueButtonDisabled = useMemo(() => {
    const isServiceTimeAdded = !isOrderNow && !serviceTime?.time

    if (isBoxPickup) {
      return isServiceTimeAdded || !selectedSection
    }

    return isServiceTimeAdded
  }, [isBoxPickup, isOrderNow, selectedSection, serviceTime?.time])

  return (
    <Modal
      isOpen={isOpen}
      title={t(
        isShowTimePicker
          ? 'newOrderTimeConfigureModal.scheduleOrder'
          : 'newOrderTimeConfigureModal.title',
      )}
      onClose={handleOnClose}
      className={styles.modal}
      mobileModalMode={isShowTimePicker ? 'full-screen' : 'bottom-sheet'}
      CustomModalHeader={CustomHeader}
      preventOnClose={isBoxPickup && isOutletPage}
    >
      {isShowTimePicker ? (
        <OrderTimeSelectionStep
          selectedOrderFormat={selectedOrderFormat!}
          initialServiceTime={
            outletFilterServiceTime?.time
              ? outletFilterServiceTime
              : serviceTime
          }
          outletListItem={outletListItem!}
          onApply={handleOnChangeServiceTime}
        />
      ) : (
        <div className={styles.wrapper}>
          <BodyText color="body-1" className={styles.infoText}>
            {t(
              isBoxPickup
                ? 'newOrderTimeConfigureModal.boxPickup.whenInfo'
                : 'newOrderTimeConfigureModal.info',
            )}
          </BodyText>
          {!isBoxPickup && (
            <div className={styles.orderTimeSwitch}>
              <Button
                variant="secondary"
                className={clsx({
                  [styles.active]: isOrderNow,
                  [styles.disabled]: isAcceptingPreOrders,
                })}
                onClick={handleOnClickOrderNow}
                disabled={isAcceptingPreOrders}
              >
                {t('orderTimeSwitch.orderNow')}
              </Button>
              <Button
                variant="secondary"
                className={clsx({
                  [styles.active]: !isOrderNow,
                })}
                onClick={handleOnClickOrderLater}
              >
                {t('orderTimeSwitch.later')}
              </Button>
            </div>
          )}
          {(!isOrderNow || isBoxPickup) && (
            <section className={styles.dateTimeSection}>
              <OrderConfigureTimeInfo
                serviceTime={
                  outletFilterServiceTime?.time
                    ? outletFilterServiceTime
                    : serviceTime
                }
                onClickScheduleOrder={handleOnClickScheduleOrder}
              />
              {isBoxPickup && (
                <BoxPickupSections
                  onChangeSection={setSelectedSection}
                  selectedSection={selectedSection}
                  sections={sections}
                  isLoading={isSectionsFetching}
                />
              )}
            </section>
          )}
          <Button
            className={styles.continueButton}
            disabled={isContinueButtonDisabled}
            onClick={handleOnClickContinue}
          >
            {t('newOrderTimeConfigureModal.continue')}
          </Button>
        </div>
      )}
    </Modal>
  )
}
